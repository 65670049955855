.article {
  margin: 0 auto 0 auto;
  padding: 2em;
  width: 90%;
  max-width: 1000px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.13);
}

.articleheader {
  margin-bottom: 1em;
}

.articleheader .articletitle, .articledate {
  margin: 0;
}

.articledate {
  font-style: italic;
}

.articlecontent {
  line-height: 1.5em;
}

.articlecontent p {
  margin: 1.5em 0 1.5em 0;
}

.articlecontent li {
  margin-bottom: 1em;
}

.articlecontent img {
  max-width: 100%;
  height: auto;
}

.postpreview {
  margin: 2em auto 2em auto;
  padding: .5em 1em .5em 1em;
  width: 80%;
  max-width: 800px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.13);
  background-color: white;
}

.postthumbnail {
  display: inline-block;
  box-sizing: border-box;
  width: calc(35% - 1em);
  height: 100%;
  vertical-align: top;
}

.postimage {
  display: block;
  width: 100%;
  height: auto;
}

.postdetails {
  display: inline-block;
  box-sizing: border-box;
  width: calc(65% - 1em);
}

.posttitle {
  color: #464646;
  margin-bottom: 0;
}

.postdate {
  font-style: italic;
  margin-top: 0;
}

.postblip {
  color: slategrey;
}
