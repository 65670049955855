body {margin-top: 0px;margin-left: 0px;}

#encapsulate{
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
}

#page_1 {position:relative; overflow: hidden;padding: 27px 15px 30px 60px;margin:0px;border: none;width: 723px;background-color:#ffffff !important;float:center;box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.13);display:inline-block;}

.ft0{font: 32px 'Times New Roman';color: #3872b2;line-height: 36px;}
.ft1{font: 15px 'Times New Roman';color: #727272;line-height: 17px;}
.ft2{font: 13px 'Times New Roman';line-height: 15px;}
.ft3{font: 13px 'Arial';line-height: 16px;}
.ft4{font: 13px 'Times New Roman';text-decoration: underline;color: #1155cc;line-height: 15px;}
.ft5{font: 13px 'Times New Roman';color: #1155cc;line-height: 15px;}
.ft6{font: 1px 'Times New Roman';line-height: 1px;}
.ft7{font: 17px 'Times New Roman';color: #3872b2;line-height: 19px;}
.ft8{font: 15px 'Times New Roman';line-height: 17px;}
.ft9{font: italic 13px 'Times New Roman';line-height: 15px;}
.ft10{font: italic 15px 'Times New Roman';line-height: 17px;}
.ft11{font: 11px 'Arial';line-height: 14px;}
.ft12{font: 13px 'Times New Roman';margin-left: 17px;line-height: 15px;}
.ft13{font: italic 14px 'Times New Roman';line-height: 16px;}
.ft14{font: 13px 'Times New Roman';margin-left: 17px;line-height: 16px;}
.ft15{font: 13px 'Times New Roman';line-height: 16px;}
.ft16{font: 13px 'Times New Roman';margin-left: 16px;line-height: 15px;}
.ft17{font: 13px 'Times New Roman';margin-left: 23px;line-height: 15px;}
.ft18{font: 13px 'Times New Roman';margin-left: 23px;line-height: 17px;}
.ft19{font: 13px 'Times New Roman';line-height: 17px;}
.ft20{font: 11px 'Times New Roman';line-height: 14px;}

.p0{text-align: left;padding-left: 208px;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: left;padding-left: 180px;margin-top: 4px;margin-bottom: 0px;}
.p2{text-align: left;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p3{text-align: left;padding-left: 38px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p4{text-align: left;margin-top: 9px;margin-bottom: 0px;}
.p5{text-align: right;padding-right: 7px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p6{text-align: left;padding-left: 11px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p7{text-align: right;padding-right: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p8{text-align: right;padding-right: 2px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p9{text-align: right;padding-right: 1px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p10{text-align: right;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p11{text-align: left;margin-top: 8px;margin-bottom: 0px;}
.p12{text-align: left;padding-left: 42px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p13{text-align: right;padding-right: 451px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p14{text-align: left;padding-left: 24px;margin-top: 0px;margin-bottom: 0px;}
.p15{text-align: left;padding-left: 24px;margin-top: 1px;margin-bottom: 0px;}
.p16{text-align: left;padding-left: 67px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p17{text-align: left;padding-left: 199px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p18{text-align: left;padding-left: 48px;padding-right: 106px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p19{text-align: justify;padding-left: 48px;padding-right: 121px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p20{text-align: justify;padding-left: 48px;padding-right: 130px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p21{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p22{text-align: left;padding-left: 87px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
.p23{text-align: left;padding-left: 18px;margin-top: 0px;margin-bottom: 0px;}
.p24{text-align: left;margin-top: 11px;margin-bottom: 0px;}
.p25{text-align: left;padding-left: 48px;padding-right: 94px;margin-top: 2px;margin-bottom: 0px;text-indent: -30px;}
.p26{text-align: left;padding-left: 32px;margin-top: 3px;margin-bottom: 0px;}
.p27{text-align: left;margin-top: 16px;margin-bottom: 0px;}

.td0{padding: 0px;margin: 0px;width: 466px;vertical-align: bottom;}
.td1{padding: 0px;margin: 0px;width: 162px;vertical-align: bottom;}
.td2{padding: 0px;margin: 0px;width: 4px;vertical-align: bottom;}
.td3{padding: 0px;margin: 0px;width: 462px;vertical-align: bottom;background: #888888;}
.td4{padding: 0px;margin: 0px;width: 161px;vertical-align: bottom;background: #888888;}
.td5{padding: 0px;margin: 0px;width: 1px;vertical-align: bottom;}
.td6{padding: 0px;margin: 0px;width: 517px;vertical-align: bottom;}
.td7{padding: 0px;margin: 0px;width: 107px;vertical-align: bottom;}
.td8{padding: 0px;margin: 0px;width: 487px;vertical-align: bottom;}
.td9{padding: 0px;margin: 0px;width: 142px;vertical-align: bottom;}
.td10{padding: 0px;margin: 0px;width: 462px;vertical-align: bottom;}
.td11{padding: 0px;margin: 0px;width: 167px;vertical-align: bottom;}
.td12{padding: 0px;margin: 0px;width: 336px;vertical-align: bottom;}
.td13{padding: 0px;margin: 0px;width: 294px;vertical-align: bottom;}
.td14{padding: 0px;margin: 0px;width: 480px;vertical-align: bottom;}
.td15{padding: 0px;margin: 0px;width: 150px;vertical-align: bottom;}
.td16{padding: 0px;margin: 0px;width: 141px;vertical-align: bottom;}
.td17{padding: 0px;margin: 0px;width: 431px;vertical-align: bottom;}
.td18{padding: 0px;margin: 0px;width: 197px;vertical-align: bottom;}

.tr0{height: 22px;}
.tr1{height: 18px;}
.tr2{height: 1px;}
.tr3{height: 20px;}
.tr4{height: 16px;}
.tr5{height: 26px;}
.tr6{height: 17px;}
.tr7{height: 19px;}
.tr8{height: 23px;}

.t0{width: 628px;font: 13px 'Times New Roman';}
.t1{width: 624px;font: italic 13px 'Times New Roman';}
.t2{width: 629px;font: 15px 'Times New Roman';}
.t3{width: 629px;margin-top: 2px;font: 15px 'Times New Roman';}
.t4{width: 630px;margin-top: 2px;font: 15px 'Times New Roman';}
.t5{width: 630px;margin-top: 1px;font: 15px 'Times New Roman';}
.t6{width: 621px;font: 15px 'Times New Roman';}
.t7{width: 628px;font: 15px 'Times New Roman';}
