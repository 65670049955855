.App {
  text-align: center;
}

body {
    background-color: #232729 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex: 1;
  justify-contents: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px){
    .HeaderTerminal {
	display: none;
    }
}

.HeaderTerminal{
    display: flex;
    flex: .6;
    justify-content: left;
    align-content: left;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.3);
}

.App-header > div > p {
    line-height: 1.1em;
}

.start {
    color: #096b23;
    font-size: calc(10px + 6vmin);
    font-family: "Brush Script MT, Brush Script Std, cursive";
}

.start-green{
    color: #096b23;
}

.terminal{
    background-color: #232729;
    color: #096b23;
    width: 100%;
    border: 0;
    outline: none;
    resize: none;
    margin-left: 2px;
}


.prompt{
    color: #096b23;
    height: 0;
    width: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    word-break: break-all;
}



.ourNavBar > .navbar{
    
    
}

.ourNavBar:hover > .navbar{
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.13);
    transition: box-shadow 1.25s;
}


.ourNavBar:hover .prompt{
    height: 20px;
    margin: 5px;
    overflow-x: visible;
    width: 100%;
    transition: height 1.25s, margin 1.25s, width 5s;
}



/*
height:0px;
    color: white;
    overflow: hidden;


*/

.namePlate{
    text-size: calc(10 + 8vmin);
}

.internalStartPage{
    visibility: hidden;
    opacity: 0;
}

.App-Nametag{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 10%;
}

.App-Nametag:hover .internalStartPage{
    visibility: visible;
    opacity: 1;
    transition: visibility 3s, opacity 3s;
}
